<template>
  <Banner />
  <CloudProducts />
  <Introduce />
  <Client />
  <Certification />
  <Footer />
  <CopyRight />
</template>

<script>
import Banner from '@/components/banner.vue'
import CloudProducts from '@/components/home/cloud-products.vue'
import Introduce from '@/components/home/introduce.vue'
import Client from '@/components/home/client.vue'
import Certification from '@/components/home/certification.vue'
import Footer from '@/components/footer.vue'
import CopyRight from '@/components/copy-right.vue'

export default {
  name: 'Home',
  components: {
    Banner,
    CloudProducts,
    Introduce,
    Client,
    Certification,
    CopyRight,
    Footer
  }
}
</script>
